import React, { Fragment } from 'react';
import { Container, Row, Col, Form} from 'react-bootstrap';
import Soundbulk from './components/soundbulk';
import axios from 'axios';
import { Helmet } from "react-helmet";

// PROD
//var requesturl = 'https://z7ozwiojl8.execute-api.eu-north-1.amazonaws.com/production/api/v1/';
// DEV
//var requesturl = 'https://co6y8bs1jh.execute-api.eu-north-1.amazonaws.com/development/api/v1/';
// LOCAL
//var requesturl = 'http://localhost:8101/api/v1/';

var nCols = 2;

var categoriesAndSounds = []
var nCategories = 0
var nSounds = 0

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.fetchData();

        this.state = {
            filter: '',
            cols: Array.from({ length: nCols }, () => [])
        }
        this.updateInput = this.updateInput.bind(this);
    }

    updateInput(event) {
        const regex = / /g;
        this.setState({ filter: event.target.value.toLowerCase().trim().replace(regex, "_") },()=>this.filterCategories())
    }

    fetchData(){
        axios.get(
            'https://soundboard-cdn.s3.eu-north-1.amazonaws.com/scripts/json_data.json',
            { headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'} },
            ).then(response => 
                categoriesAndSounds=response.data
            ).then(()=>{
                nCategories = categoriesAndSounds.length
                categoriesAndSounds.forEach(cat => nSounds += cat.sounds.length)
                this.filterCategories()
            })
    }

    splitToChunks(array, parts) {
        let result = [];
        for (let i = parts; i > 0; i--) {
            result.push(array.splice(0, Math.ceil(array.length / i)));
        }
        return result;
    }

    filterCategories(){
        var cats = []
        categoriesAndSounds.forEach(cat => {
            var filteredSounds = cat.sounds.filter(sound=>sound.fullName.includes(this.state.filter))
            var newCat = {name:cat.name,sounds:filteredSounds}
            if(filteredSounds.length!==0){
                cats.push(newCat)
            }
        })
        this.setState({cols:this.splitToChunks(cats,nCols)},()=>this.searchHits())
    }

    searchHits(){
        if(this.state.filter!='') {
            var nFilteredSounds = 0
            this.state.cols.forEach(col => col.forEach(cat => nFilteredSounds += cat.sounds.length))
            return (
                <div style={{textAlign: "center"}}>
                    <h4>
                        Fant {nFilteredSounds} lyder
                    </h4>
                    <br />
                </div>  
                )
        }
    }

    searchBar() {
        return (
            <Form.Control className="bg-secondary text-white" id="searchInput" aria-describedby="searchHelp" onChange={this.updateInput} placeholder={`Søk blant ${nSounds} lyder og ${nCategories} kategorier`} />
        )
    }

    render() {
        return <Fragment>
            <Helmet>
                <title>Soundboard.no</title>
                <meta name="description" content="Norsk soundboard - Lasse Gjertsen, Vikingane, Kongen, Hvite gutter og mye mer!" />
            </Helmet>
            <Container>
                <br />
                {this.searchBar()}
                <br />
                {this.searchHits()}
                <Row>
                    {this.state.cols.map((col, i) => <Col key={i}>{col.map(category => <Soundbulk key={category.name} title={category.name} sounds={category.sounds} filter={this.state.filter} />)}</Col>)}
                </Row>

            </Container>
        </Fragment>
    }
}

export default Home;
