import React, { Fragment } from 'react';
import { Container, Row, Col  } from 'react-bootstrap';
import { Helmet } from "react-helmet";

class Credits extends React.Component {
    render() {
      return (<Fragment>
        <Helmet>
            <title>Soundboard.no - Takk</title>
            <meta name="description" content="Takk til dere som har bidratt til prosjektet" />
        </Helmet>
          <Container>
              <Row>
                  <Col>
                     <div>
                       <br></br>
                        <h1>Takk <span aria-label="" role="img" alt="Hjerte emoji">❤️</span></h1>
                        <p>Takk til dere som bidrar til prosjektet med å sende inn innhold, og ikke minst takk til deg som bruker Soundboard.no!</p>
                        <p>En ekstra takk til:</p>
                        <ul>
                          <li>Xoriath</li>
                          <li>Gilmanspoint</li>
                          <li>Bendikflu</li>
                          <li>Fleebs</li>
                          <li>Sotez</li>
                          <li>Iengzeh</li>
                          <li>Nectuz</li>
                          <li>BarRaider - Utvikler av Streamdeck integrasjon til Soundpad</li>
                          <li>Bristol</li>
                        </ul>
                        <p>Lyst til å sende oss en lyd og bli med på listen over? <a href="/send">Klikk her!</a></p>
                     </div>
                 </Col>
             </Row>
          </Container>
       </Fragment>
      )
    }
}

export default Credits;
