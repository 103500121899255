import React, { Fragment } from 'react';
import { Container, Row, Col  } from 'react-bootstrap';
import { Helmet } from "react-helmet";

class Send extends React.Component {
    render() {
      return (<Fragment>
        <Helmet>
            <title>Soundboard.no - Send inn lyder</title>
            <meta name="description" content="Norsk soundboard - Send oss dine lyder!" />
        </Helmet>
          <Container>
              <Row>
                  <Col>
                     <div>
                       <br></br>
                        <h1>Send inn lyder</h1>
                        <p>Vi vil gjerne ha flere lyder som vi kan legge inn!</p>
                        <h5>Ønskede krav til innsending</h5>
                        <p>For å gjøre livet vårt litt lettere er det supert om du gjør følgende arbeid før du sender inn lyder:</p>
                        <ul>
                          <li>Legg filen i en mappe som beskriver hvor/hva lyden er hentet fra. F.eks: kaptein_sabeltann/landkrabber.mp3</li>
                          <li>Bonuspoeng om du sørger for at lydkvaltieten er god. Ingen skurring.</li>
                          <li>Vi sørger for filkonverting og justering av lydnivå. Det trenger ikke du tenke på :)</li>
                          <li>Om du ønsker å havne på <a href="/takk">Takk til</a> siden må du oppgi hva du ønsker vi skal kreditere deg som</li>
                        </ul>
                        <p>Send oss lyden på e-post her: <a href="mailto:lyd@soundboard.no">lyd@soundboard.no</a></p>
                     </div>
                 </Col>
             </Row>
          </Container>
       </Fragment>
      )
    }
}

export default Send;
